import React from 'react'

function DetailsPage() {
  return (
    <div>
      
    </div>
  )
}

export default DetailsPage
